<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/'+this.pluralLower">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Name"
            :disabled="!this.isEditable"
            outlined
            v-model="data.name"
          />
          <v-text-field
              label="Description"
              :disabled="!this.isEditable"
              outlined
              v-model="data.description"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="update">Save</v-btn>
          <v-btn style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>Orders</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              :headers="secondaryHeaders"
              :items="data.users"
              :items-per-page="1000000"
              class="elevation-1"
              @click:row="secondaryRowClick"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {},
        singular: "Order Type",
        singularLower: "ordertype",
        plural: "Order Types",
        pluralLower: "ordertypes",
        isEditable: false,
        secondaryHeaders: [
          {
            text: 'Customer2',
            align: 'start',
            sortable: true,
            value: 'customerName',
          },
          { text: "Total", value: 'orderTotal'},
          { text: "Items", value: 'numItems'}
        ],
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          this.data = res.data
        } catch (error) {
          console.log(error)
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.success){
            this.snackText = `${this.singular} info Updated 🎉`;
            this.snack = true;
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.status == 200){
            this.snackText = this.singular+" Deleted 🎉";
            this.snack = true;
            setTimeout(()=>{
              this.$router.push({ path: `/${this.pluralLower}`})
            }, 1000)
          }
        } catch (error) {
          console.log(error)
        }
      },
      secondaryRowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
    }
  }
</script>
